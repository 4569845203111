import { useEffect, useState, Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardActions, Grid, Stack, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';


import { ManagerToSetWithEmploye, ObjectiveAppraisal } from '../performance';
import ReactRating from '../../../components/rating/ReactRating';
import { useUpdateManagerRatingMutation } from '../../../features/manager/managerApi';
import ToastAlert from '../../../components/alert/ToastAlert';
import PopUpModal from '../../../components/modal/PopUpModal';
import { useGetAnEmployeeRatingQuery } from '../../../features/review/reviewApi';

UpdateMangerReviewForm.propTypes = {
    task: PropTypes.object,
    RatingQA: PropTypes.object,
    empId: PropTypes.number,
    draftData: PropTypes.object,
    employeeRating: PropTypes.object,
};

export default function UpdateMangerReviewForm({ task, employeeRating, draftData, RatingQA, empId, id }) {


    const { user } = useSelector((state) => state.auth);
    const [updateManagerRating, { data, isSuccess, isLoading }] = useUpdateManagerRatingMutation()
    const [managerRate, setManagerRate] = useState(false);
    const [expanded, setExpanded] = useState(['managerRate', 'objective', 'managertoEmployee']);
    const [managertoEmployee, setmanagertoEmployee] = useState(false)
    const [draft, setDraft] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [currentField, setCurrentField] = useState(null);
    const [isEditable, setIsEditable] = useState(false);

    const handleClickOpen = (field, content, question, editable) => {
        setCurrentField(field);
        setDialogTitle(question);
        setDialogContent(content);
        setOpenDialog(true);
        setIsEditable(editable);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSave = (setFieldValue) => {
        setFieldValue(currentField, dialogContent)
        setOpenDialog(false);
    };

    // const handleChange = (panel) => {
    //     // setExpanded(isExpanded ? panel : false);

    //     // if (document.getElementById('panel2bh-headersec1').classList.contains('Mui-expanded')) {
    //     //     document.getElementById('panel2bh-headersec1').click();
    //     // }


    //     switch (panel) {
    //         case "objective":
    //             setObjective(val => !val);
    //             setManagerRate(false);
    //             setmanagertoEmployee(false);

    //             break;
    //         case "manager":
    //             setManagerRate(val => !val);
    //             setObjective(false);
    //             setmanagertoEmployee(false);

    //             break;
    //         case "managertoEmployee":
    //             setObjective(false);
    //             setManagerRate(false);
    //             setmanagertoEmployee(val => !val);

    //             break;
    //         default:
    //             break;
    //     }
    // };

    const handleChange = (panel) => (event, isExpanded) => {
        console.log(panel);
        setExpanded((prevExpanded) =>
          isExpanded
            ? [...prevExpanded, panel]
            : prevExpanded.filter((item) => item !== panel)
        );
      };


    const initialvaluesManagerRate = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`managerRate-${curr?.reviewRatingQuestionId}`]: draftData?.managerRatingModels?.find((item) => item?.reviewRatingQuestionId === curr?.reviewRatingQuestionId)?.managerRating,
        [`employeeManagerPRRatingId-${curr?.reviewRatingQuestionId}`]: draftData?.managerRatingModels?.find((item) => item?.reviewRatingQuestionId === curr?.reviewRatingQuestionId)?.employeeManagerPRRatingId,
    }), {});
    const initialvaluesManagerRateComment = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`managerRateComment-${curr?.reviewRatingQuestionId}`]: draftData?.managerRatingModels?.find((item) => item?.reviewRatingQuestionId === curr?.reviewRatingQuestionId)?.managerComment,
    }), {});
    const initialvaluesSelfAppraisalManagerComment = employeeRating?.employeeSelfApprasalQAs?.reduce((acc, curr) => ({
        ...acc,
        [`managerSelfAppraisalAnswer-${curr?.selfAppraisalQuestionId}`]: employeeRating?.employeeSelfApprasalQAs?.find((item) => item?.selfAppraisalQuestionId === curr?.selfAppraisalQuestionId)?.managerAnswer,
    }), {});
    const smartTarget = [0, 1, 2].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-id-${curr}`]: employeeRating?.managerSmartTargetQuestions?.filter(x => x.employeeRating !== 0)[curr]?.id,
        [`managerSmartTargetQuestions-managerComments-${curr}`]: employeeRating?.managerSmartTargetQuestions?.filter(x => x.employeeRating !== 0)[curr]?.managerComments,
        [`managerSmartTargetQuestions-managerRating-${curr}`]: employeeRating?.managerSmartTargetQuestions?.filter(x => x.employeeRating !== 0)[curr]?.managerRating,
    }), {});
    console.log("smartTarget==>", smartTarget);
    const smartTarget2 = [3, 4, 5].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-smartTargetQuestionsId-${curr}`]: draftData?.managerSmartTargets.filter(x => x.employeeRating === 0)[curr - 3]?.smartTargetQuestionsId,
        [`managerSmartTargetQuestions-description-${curr}`]: draftData?.managerSmartTargets.filter(x => x.employeeRating === 0)[curr - 3]?.description,
        [`managerSmartTargetQuestions-timeline-${curr}`]: draftData?.managerSmartTargets.filter(x => x.employeeRating === 0)[curr - 3]?.timeline,
        [`managerSmartTargetQuestions-managerComments-${curr}`]: draftData?.managerSmartTargets.filter(x => x.employeeRating === 0)[curr - 3]?.managerComments,
        // [`managerSmartTargetQuestions-employeeRating-${curr}`]: "",
        // [`managerSmartTargetQuestions-managerRating-${curr}`]: "",
    }), {});
    const initialvalues = {
        ...initialvaluesManagerRate,
        ...initialvaluesManagerRateComment,
        ...initialvaluesSelfAppraisalManagerComment,
        ...smartTarget,
        ...smartTarget2,
        areasIdentifiedforTrainingDevelopmentManagerComment: employeeRating?.areasIdentifiedforTrainingDevelopmentManagerComment,
    }

    // create validation schema dynamically using the questions array

    const ratingSchema = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,

    }), {});
    const commentSchema = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`managerRateComment-${curr?.reviewRatingQuestionId}`]: yup.string().required("Required"),
    }), {});

    const smartTargetSchema = [0, 1, 2].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-managerComments-${curr}`]: yup.string().required("Required"),

    }), {});
    const smartTargetSchema2 = [3, 4, 5].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-description-${curr}`]: yup.string().required("Required"),
        [`managerSmartTargetQuestions-timeline-${curr}`]: yup.string().required("Required"),
        [`managerSmartTargetQuestions-managerComments-${curr}`]: yup.string().required("Required"),
    }), {});

    const validationSchema = yup.object({
        ...ratingSchema,
        ...commentSchema,
        ...smartTargetSchema,
        ...smartTargetSchema2,
    });
    const onSubmit = (values) => {
        const managerRateComment = Object.keys(values).filter((key) => key.includes("managerRateComment"))

        let employeeSelfApprasalQAModels = Object.keys(values).filter((key) => key.includes("managerSelfAppraisalAnswer"))

        employeeSelfApprasalQAModels = employeeSelfApprasalQAModels.map((key) => key.includes("managerSelfAppraisalAnswer") && {
            selfAppraisalQuestionId: key.split("-")[1],
            managerAnswer: values[key],

        })

        const managerRatingModels = managerRateComment.map((key) => {
            const result = employeeRating?.employeeRatingModels?.find((data) => data.reviewRatingQuestionId === Number(key?.split("-")[1]));
            return ({
                reviewRatingQuestionId: key.split("-")[1],
                managerRating: values[`managerRate-${key?.split("-")[1]}`],
                managerComment: values[`managerRateComment-${key?.split("-")[1]}`],
                employeePRRatingId: result?.employeePRRatingId,
                employeeManagerPRRatingId: values[`employeeManagerPRRatingId-${key?.split("-")[1]}`],
            })
        })

        const managerSmartTargetQuestionsDescription = Object.keys(values).filter((key) => key.includes("managerSmartTargetQuestions-description"));

        const employeeSmartTargetQuestionsDescription = Object.keys(values).filter((key) => key.includes("managerSmartTargetQuestions-managerRating"));

        const employeeSmartTargetQuestions = employeeSmartTargetQuestionsDescription.map((key) => ({
            ...employeeRating?.data?.managerSmartTargetQuestions[key?.split("-")[2]],
            rowNumber: key.split("-")[2],
            Id: values[`managerSmartTargetQuestions-id-${key?.split("-")[2]}`],
            managerComments: values[`managerSmartTargetQuestions-managerComments-${key?.split("-")[2]}`],
            managerRating: values[`managerSmartTargetQuestions-managerRating-${key?.split("-")[2]}`],

        }))
        const managerSmartTargetQuestions = managerSmartTargetQuestionsDescription.map((key) => ({
            rowNumber: key.split("-")[2],
            smartTargetQuestionsId: values[`managerSmartTargetQuestions-smartTargetQuestionsId-${key?.split("-")[2]}`],
            description: values[`managerSmartTargetQuestions-description-${key?.split("-")[2]}`],
            timeline: values[`managerSmartTargetQuestions-timeline-${key?.split("-")[2]}`],
            managerComments: values[`managerSmartTargetQuestions-managerComments-${key?.split("-")[2]}`],
        }))

        updateManagerRating({
            employeeManagerReviewDetailsId: draftData?.employeeManagerReviewDetailsId,
            status: draft ? "Draft" : "Submitted",
            employeeId: empId,
            dateofReview: new Date(),
            managerId: user?.id,
            employeeSelfApprasalQAModels,
            managerRatingModels,
            managerSmartTargetQuestions,
            employeeSmartTargetQuestions,
            areasIdentifiedforTrainingDevelopmentManagerComment: values.areasIdentifiedforTrainingDevelopmentManagerComment,
        });

    }
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setDraft(false);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }, [data?.isSuccess, isSuccess])

    const handleDownload = async (fileUrl) => {
        try {
            // const baseurl=  "https://localhost:7083/"; 
            const baseurl = process.env.REACT_APP_BASE_URL;
            const link = document.createElement('a');
            link.href = `${`${baseurl}File/${fileUrl}/true`}`;
            link.target = "_blank";
            link.setAttribute('download', fileUrl);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error occurred while downloading the file:', error);
        }
    };

    return (
        <Formik enableReinitialize
            initialValues={initialvalues}
            validationSchema={draft ? null : validationSchema}
            onSubmit={onSubmit}
        >
            {
                ({ handleSubmit, setFieldValue }) => (
                    <Form>
                        {data?.isSuccess && isSuccess && <ToastAlert msg={data?.message} />}
                        <Accordion expanded={expanded.includes('managerRate')} onChange={handleChange('managerRate')}>
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-headersec2"
                            >
                                <Typography variant='h5' >
                                    Section 1 - Employee Rating
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="h5" mb={3} color="initial">
                                    Self Appraisals
                                </Typography>
                                {employeeRating?.employeeSelfApprasalQAs?.map((data) =>
                                    <Stack
                                        key={data?.selfAppraisalQuestionId}
                                        mt={2}
                                        alignItems={'center'}
                                        direction={{ xs: 'column', sm: 'row' }}
                                        spacing={{ xs: 10, sm: 10, md: 10 }}
                                    >
                                        <Box maxWidth={"650px"} width={"100%"} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={2}
                                                label={`${data?.selfAppraisalQuestionId}. ${data?.question}`}
                                                value={data?.answer}
                                                disabled
                                                sx={{ mb: 2 }}
                                                variant="outlined"
                                            />
                                            <Box onClick={() => handleClickOpen(`selfApprasal-${data?.selfAppraisalQuestionId}`, data?.answer, data?.question, false)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                            </Box>
                                        </Box>
                                        <Box maxWidth={"750px"} width={"100%"} >

                                            <Field name={`managerSelfAppraisalAnswer-${data?.selfAppraisalQuestionId}`}>
                                                {({ field, form }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            rows={2}
                                                            label={`${data?.selfAppraisalQuestionId}. ${data?.question}`}
                                                            sx={{ mb: 2 }}
                                                            variant="outlined"
                                                            {...field}
                                                            value={field.value || ""} 

                                                            
                                                        />
                                                        <Box onClick={() => handleClickOpen(`managerSelfAppraisalAnswer-${data?.selfAppraisalQuestionId}`, data?.managerAnswer??"", `${data?.selfAppraisalQuestionId}. ${data?.question}`, true)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Field>

                                        </Box>
                                    </Stack>

                                )}
                                {RatingQA?.data?.map((data, key) =>
                                    <Stack
                                        key={key}
                                        mt={2}
                                        alignItems={'center'}
                                        direction={{ xs: 'column', sm: 'row' }}
                                        spacing={{ xs: 1, sm: 1, md: 1 }}
                                    >
                                        <Box maxWidth={"450px"} width={"100%"} >
                                            <Typography variant='subtitle1'> {data.question} </Typography>
                                            <Typography variant='body2'> {data.description} </Typography>
                                        </Box>
                                        <Box maxWidth={"180px"} width={"100%"}>
                                            <ReactRating readonly initialRating={employeeRating?.employeeRatingModels?.find((e) => e.reviewRatingQuestionId === data.reviewRatingQuestionId)?.employeeRating || 0} />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField sx={{ width: 300 }}
                                                disabled
                                                multiline
                                                rows={"2"}
                                                placeholder={data?.Description}
                                                variant="outlined"
                                                value={employeeRating?.employeeRatingModels?.find((e) => e.reviewRatingQuestionId === data.reviewRatingQuestionId)?.employeeComment}
                                            />
                                            <Box onClick
                                                =
                                                {() => handleClickOpen(
                                                    `employeeRatingModels.${data.reviewRatingQuestionId}.employeeComment`,
                                                    employeeRating?.employeeRatingModels?.find((e) => e.reviewRatingQuestionId === data.reviewRatingQuestionId)?.employeeComment || '',
                                                    'Edit Comment',
                                                    false
                                                )} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                            </Box>
                                        </Box>
                                        <Box maxWidth={"180px"} width={"100%"}>
                                            <Field name={`managerRate-${data?.reviewRatingQuestionId}`}>
                                                {({ form, field }) => {
                                                    const { setFieldValue } = form;
                                                    const { value } = field;
                                                    return (
                                                        <ReactRating
                                                            initialRating={Number(value)}
                                                            onChange={(val) => setFieldValue(`managerRate-${data?.reviewRatingQuestionId}`, val)}
                                                            value={data.Rating} />
                                                    )
                                                }}
                                            </Field>

                                            <ErrorMessage name={`managerRate-${data?.reviewRatingQuestionId}`}>
                                                {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                            </ErrorMessage>
                                        </Box>
                                        <Box maxWidth={"300px"} width={"100%"}>
                                            <Field name={`managerRateComment-${data?.reviewRatingQuestionId}`}>
                                                {({ field, form }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            sx={form.submitCount && form.errors[field.name] && { border: "2px solid red", borderRadius: "8px" }}
                                                            fullWidth
                                                            multiline
                                                            rows={"2"}
                                                            placeholder={data?.Description}
                                                            label="Mgr Comments"
                                                            variant="outlined"
                                                            {...field}
                                                            value={field.value || ""} 
                                                        />
                                                        <Box onClick={() => handleClickOpen(`managerRateComment-${data?.reviewRatingQuestionId}`, field.value, 'Edit Comment', true)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                                        </Box>
                                                    </Box>

                                                )}
                                            </Field>
                                            <ErrorMessage name={`managerRateComment-${data?.reviewRatingQuestionId}`}>
                                                {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                            </ErrorMessage>
                                        </Box>
                                    </Stack>
                                )}
                                <Grid container spacing={3} my={5}>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'> Areas Identified for Training & Development/ Additional Comments </Typography>
                                        <Stack
                                            key={data?.selfAppraisalQuestionId}
                                            mt={2}
                                            alignItems={'center'}
                                            direction={{ xs: 'column', sm: 'row' }}
                                            spacing={{ xs: 10, sm: 10, md: 10 }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <TextField
                                                    value={employeeRating?.areasIdentifiedforTrainingDevelopment}
                                                    fullWidth
                                                    multiline
                                                    disabled
                                                    rows={"2"}
                                                />
                                                <Box onClick={() => handleClickOpen('areasIdentifiedforTrainingDevelopmentManagerComment', employeeRating?.areasIdentifiedforTrainingDevelopment, 'Edit Area Identified for Training and Development', false)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                    <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                                </Box>
                                            </Box>
                                            <Field name="areasIdentifiedforTrainingDevelopmentManagerComment">
                                                {({ field, form }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <TextField
                                                            rows={"2"}
                                                            fullWidth
                                                            multiline
                                                            sx={{ mb: 2, width: "100%" }}
                                                            variant="outlined"
                                                            {...field}
                                                            value={field.value || ""} 
                                                        />
                                                        <Box onClick={() => handleClickOpen('areasIdentifiedforTrainingDevelopmentManagerComment', field.value, 'Edit Area Identified for Training and Development', true)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Field>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded.includes('objective')} onChange={handleChange('objective')}>
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-headersec3"
                            >
                                <Typography variant='h5' >
                                    Section 2 - Objectives Appraisal
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ObjectiveAppraisal reviewreating={draftData} empId={empId} employeeRatingAvg={employeeRating?.employeeRatingAvg} managerRatingAvg={employeeRating?.managerRatingAvg} />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded.includes('managertoEmployee')} onChange={handleChange('managertoEmployee')}>
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-headersec4"
                            >
                                <Typography variant='h5' >
                                    Section 3 – Objectives Set for next year
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ManagerToSetWithEmploye />
                            </AccordionDetails>
                        </Accordion>

                        <Grid container spacing={3} my={5}>



                            {draftData?.status !== "Draft" || task !== undefined ?

                                <Grid item xs={3} >
                                    <Box>
                                        <LoadingButton loading={isLoading} type='submit' variant="contained" fullWidth> Save Changes  </LoadingButton>
                                    </Box>
                                </Grid> :
                                <>
                                    <Grid item xs={3} >
                                        <Box>
                                            <LoadingButton type='button' onClick={() => setDraft(true)} color='inherit' variant="contained" fullWidth> Draft </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Box>

                                            <LoadingButton loading={isLoading} type='submit' variant="contained" fullWidth> Submit  </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Box>

                                            <LoadingButton type='button' variant="contained" color='inherit' fullWidth onClick={() => handleDownload(employeeRating?.employeeReviewDetailsId)} > Print  </LoadingButton>
                                        </Box>
                                    </Grid>
                                </>


                            }


                            <PopUpModal open={draft} setOpen={setDraft}>
                                <Typography id="modal-modal-title" variant="h6" textAlign={"center"} component="h2" mb={2}>
                                    Are you sure you want to save as draft?
                                </Typography>
                                <CardActions>
                                    <Button type='button' onClick={() => setDraft(false)} color='inherit' fullWidth variant="contained">
                                        Cancel
                                    </Button>
                                    <LoadingButton loading={isLoading} variant="contained" onClick={handleSubmit} fullWidth> save as draft </LoadingButton>
                                </CardActions>
                            </PopUpModal>

                            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="lg">
                                <DialogTitle>{dialogTitle}</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        fullWidth
                                        multiline
                                        value={dialogContent}
                                        onChange={(e) => setDialogContent(e.target.value)}
                                        variant="outlined"
                                        disabled={!isEditable}
                                        InputProps={{style: { minHeight: '200px', alignItems: 'start' } }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">Close</Button>
                                    {isEditable && <Button onClick={() => handleSave(setFieldValue)} color="primary">Save</Button>}
                                </DialogActions>
                            </Dialog>

                        </Grid>

                    </Form>
                )
            }
        </Formik >
    )
}
